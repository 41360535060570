// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode
@import "../../styles/variables/index.scss";
@import "../../styles/variables/_colors.scss";
@import "../../styles/_mixins.scss";

:global(.rtl) {
  :local {
    .trackHorizontal {
      left: unset !important;
      right: 0 !important;
    }
  }
}

.scrollbar {
  &:global(.trackYVisible.trackXVisible) {
    .trackVertical {
      height: calc(100% - 16px) !important;
    }

    .trackHorizontal {
      width: calc(100% - 16px) !important;
    }
  }

  &.noScrollY {
    .trackVertical {
      height: 0 !important;
    }
  }

  // fix when iframe breaks dragging scroll
  &:has(> .track > :global(.dragging)) {
    iframe {
      pointer-events: none;
    }
  }
}

.scroller {
  &.ios::-webkit-scrollbar {
    display: none;
  }
}

.scrollBody {
  padding-inline-end: var(--scrollbar-padding-inline-end) !important;
  position: relative;
  outline: none;

  @include mobile {
    padding-inline-end: var(--scrollbar-padding-inline-end-mobile) !important;
  }
}

.track {
  box-sizing: border-box;
  display: flex;
  padding: 4px;
  border-radius: 8px !important;
  background: transparent !important;
  z-index: 201;

  @include desktop {
    &:hover {
      .thumbVertical {
        width: 8px !important;
      }

      .thumbHorizontal {
        height: 8px !important;
      }
    }
  }

  @include tablet-and-below {
    pointer-events: none;

    .thumb {
      pointer-events: all;
    }
  }
}

.trackVertical {
  direction: var(--interface-direction);
  height: 100% !important;
  width: 16px !important;
  top: 0 !important;
  justify-content: flex-end;
}

.trackHorizontal {
  width: 100% !important;
  height: 16px !important;
  align-items: flex-end;
  direction: ltr;
  left: 0 !important;
}

.thumb {
  touch-action: none;
  background-color: var(--scrollbar-bg-color) !important;
  position: relative;
  cursor: default !important;

  &:hover {
    background-color: var(--scrollbar-hover-bg-color) !important;
  }

  &:active,
  &:global(.dragging) {
    touch-action: none;
    background-color: var(--scrollbar-press-bg-color) !important;
  }
}

.thumbVertical {
  width: 4px !important;
  transition: width linear 0.1s;

  @include desktop {
    &:active {
      width: 8px !important;
    }
  }

  @include tablet-and-below {
    width: 4px !important;
  }
}

.thumbHorizontal {
  height: 4px !important;
  transition: height linear 0.1s;

  @include desktop {
    &:active {
      height: 8px !important;
    }
  }

  @include tablet-and-below {
    height: 4px !important;
  }
}

.fixedSize {
  @include desktop {
    .thumbVertical {
      width: 8px !important;
    }
    .thumbHorizontal {
      height: 8px !important;
    }
  }
}

.paddingAfterLastItem {
  .scrollBody {
    padding-bottom: var(--scrollbar-padding-after-last-item) !important;
  }
}

// ------- Auto hide styles -------

.autoHide {
  // tracks hidden by default
  .track {
    opacity: 0;
    transition: opacity 0.35s;
  }

  // tracks always shown if hovered or thumb dragged
  .track:is(:hover, :has(> :global(.dragging))) {
    opacity: 1;
  }

  // tracks shown if scroll element was not auto hidden, hovered
  // and there is no another nesting hovered scroll element, dragging thumb or backdrop
  &.scrollVisible:hover:not(
      :has(
          &:hover:global(.trackYVisible),
          &:hover:global(.trackXVisible),
          .thumb:global(.dragging),
          :global(.backdrop-active)
        )
    ) {
    > .track {
      opacity: 1;
    }
  }
  // no hover logic for touch devices
  @media (hover: none) {
    &.scrollVisible:not(:has(:global(.backdrop-active))) {
      .track {
        opacity: 1;
      }
    }
  }
}
