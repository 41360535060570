@mixin no-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$mobile + 1}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile + 1}) and (max-width: #{$tablet}) {
    @content;
  }
}

@mixin tablet-and-below {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin transitional-screen-size {
  @media (max-width: #{$desktop + $info-panel-width}) {
    @content;
  }
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
