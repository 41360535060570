@import "../../styles/mixins";
@import "../../styles/variables";

.container {
  width: 100%;
  height: 32px;
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-column-gap: 8px;
  justify-items: center;
  align-items: center;

  @include tablet-and-below {
    padding-block: 4px;
    padding-inline: 16px 12px;
    grid-column-gap: 0;
  }
}

.rectangle {
  margin-inline-end: auto;
  margin-inline-start: 8px;
}
